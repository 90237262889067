<template >
    <div>
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{ $t("message.product") }}: {{model.name}}</p>
            <div>
                <el-button class="bg-none-style" size="medium" @click="close()">
                    <i class="fa-solid fa-xmark"></i>
                </el-button>
            </div>
          </div>
        </div>
        <!-- app-modal__header end -->
        <div v-loading="loadingData" >
            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4 mm-space-between">
                    <div class="w-100">
                        <div class="mb-5">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mb-2">
                                {{ $t("message.information") }}
                            </div>

                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <div class="m-1">
                                        <span class="font-bold"> {{ $t('message.product') }} </span>: {{ model.name }}
                                    </div>
                                </el-col>
                                <el-col :span="12" v-if="model.measurement">
                                    <div class="m-1">
                                        <span class="font-bold"> {{ $t('message.measurement') }} </span>: {{ model.measurement.name }}
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-if="model.allowed_colors && model.allowed_colors.length > 0" class="mb-5">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mb-2">
                                {{ $t("message.colors") }}
                            </div>

                            <el-row>
                                <el-col :span="8" v-for="(coll, index) in model.allowed_colors" :key="'col_' + index">
                                    <div class="d-flex m-1 color-container">
                                        <div class="color-box" :style="'background-color: ' + coll.color "></div> <span> {{ coll.name }} </span>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div v-if="model.allowed_packages && model.allowed_packages.length > 0" class="mb-5">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mb-2">
                                {{ $t("message.packages") }}
                            </div>

                            <el-row :gutter="20">
                                <el-col :span="12" v-for="(pack, index) in model.allowed_packages" :key="'col_' + index">
                                    <div class="m-1">
                                        <i class="fa-regular fa-cubes"></i> {{ pack.name }}
                                    </div>
                                </el-col>
                                
                            </el-row>
                        </div>

                        <div v-if="model.services && model.services.length > 0" class="mb-5">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mb-2">
                                {{ $t("message.services") }}
                            </div>

                            <el-row :gutter="20">
                                <el-col :span="12" v-for="(service, index) in model.services" :key="'col_' + index">
                                    <div class="m-1">
                                        <i class="fa fa-wrench" aria-hidden="true"></i> {{ service.name }}
                                    </div>
                                </el-col>
                                
                            </el-row>
                        </div>
                    </div>
                    <div v-if="images_list && images_list.length > 0" style="width: 500px;">
                        <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mb-2">
                            {{ $t("message.image") }}
                        </div>

                        <div v-for="(image, index) in images_list">
                            <el-image 
                                v-if="image"
                                style="height: 200px"
                                :src="image.url"
                                :preview-src-list="[image.url]">
                            </el-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import show from "@/utils/mixins/show";
  export default {
    
    mixins: [show, drawer],
    data() {
      return {
        loadingButton: false,
  
      };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "products/rules",
            model: "products/model",
            columns: "products/columns",
            images_list: "products/images_list",
            categories: "categories/treeInventory",
            currencies: "currency/inventory",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            show: "products/showForClient",
            empty: "products/empty",
            updateList: "products/getFilialProductsByCategories",
        }),
        fetchData() {
            if (!this.loadingData && this.selectedItem) {  
                this.loadingData=true        
                // BUG
                let filial_id = this.$route.params.filial_id;
                this.show({id: this.selectedItem.id, 'for_client': 1, 'filial_id': filial_id})
                .then(res => {   
                    this.loadingData = false              
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
    },
};
</script>
<style>
.color-container {
    align-items: center;
}
.color-box {
    width: 50px;
    height: 30px;
    border-radius: 2px;
    margin: 1px 5px;
    border: 1px solid #888888;
}
.product-images-container {
    display: flex;
}
.mm-space-between {
    display: flex;
    justify-content: space-between;
}
</style>