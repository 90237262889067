<template>
    <div class="p-5">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="caz-blocks-sarcho-title">
                <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                  {{ $t("message.company_y") }} : {{ filial.name }}
                </div>
              </div>
            </el-col>
  
            <el-col :span="12" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
              
            </el-col>
          </el-row>
        </div>
        <!-- end ifon-add-title-block -->
        <div class="app-modal__body">
          <div class="timeline-items__right rounded-sm w-100 p-4">
            
            <el-row :gutter="20">
              <el-col :span="6" class="mm_border_right">
                <div>
                  <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mt-3 mb-5">
                    {{ $t("message.categories") }}
                  </div>
                  <el-row :gutter="20">

                    <el-col v-for="category in filialCategoryList" :key="'cat_'+category.id" :span="24" class="mb-3">
                      <div 
                        @click="toggleActivecategories(category.id)" 
                        class="grid-text grid-content bg-purple" 
                        :class="filterForm.category_ids.includes(category.id) ? 'active-category' : ''"
                      >
                        <i class="fa-regular fa-layer-group mr-2"></i> {{ category.name }}
                      </div>
                    </el-col>

                  </el-row>
                </div>
                
                
              </el-col>

              <el-col v-loading="loadingData" :span="18">
                <div class="mm_paginate">
                  <el-col :span="12" class="mt-3">
                    <div class="caz-blocks-sarcho-title">
                      <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                        {{ $t("message.products") }}
                      </div>
                      <div class="block-sarche">
                        <div class="header__search">
                          <crm-input
                            :size="'small'"
                            :className="'w100'"
                            :class="mode ? 'input__day' : 'input__night'"
                            v-model="filterForm.search"
                            :icon="'el-icon-search'"
                          ></crm-input>
                        </div>
                      </div>
                    </div>
                  </el-col> 
                    <crm-pagination
                      @c-change="updatePagination"
                      :class="mode ? 'pagination__day' : 'pagination__night'"
                      :pagination="pagination"
                    ></crm-pagination>
                </div>
                
                <div>
                  <el-row :gutter="20">
                    <el-col :span="8" v-for="product in list" :key="'key_' + product.id" class="mb-3">
                      <div @click="edit(product)">
                        <el-card  class="product-card" shadow="hover" :body-style="{ padding: '0px' }">
                          <el-image 
                            v-if="product.images_list && product.images_list.length > 0"
                            class="mm_center"
                            :src="product.images_list[0].url">
                          </el-image>
                          <img v-else class="mm_center" src="/img/no_image.png">
                          <div style="padding: 14px;">
                            <span> {{ product.name }}</span>
                          </div>
                        </el-card>
                      </div>
                    
                    </el-col>
                  </el-row>
                </div>
                

              </el-col>
            </el-row>

          </div>
        </div>
  
        <div class="app-modal app-modal__full modal-color-bg">
          <el-drawer
            :class="mode ? 'l-modal-style' : 'd-modal-style'"
            :with-header="false"
            :visible.sync="drawerUpdate"
            size="90%"
            ref="drawerUpdate"
            @opened="drawerOpened('drawerUpdateChild')"
            @closed="drawerClosed('drawerUpdateChild')"
          >
            <show-product-info
              :selectedItem="selectedItem"
              ref="drawerUpdateChild"
              drawer="drawerUpdate"
            ></show-product-info>
          </el-drawer>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import list from "@/utils/mixins/list";
  import showProductInfo from "./components/show-product-info";
  import selectCurrency from "@/components/selects/select-currency";
  import selectMeasurement from "@/components/inventory/select-measurement";
  import selectModel from "@/components/inventory/select-product-model";
  import productComposition from "@/views/deals/components/dealProduct/product-composition";
  
  import { mapGetters, mapActions } from "vuex";
  
  export default {
      name: "products",
      mixins: [list],
      components: {
          showProductInfo,
          selectCurrency,
          selectMeasurement,
          selectModel,
          productComposition,
      },
      data() {
          return {
              drawerProductComposition: false,
          };
      },
      created(){
        let filial_id = this.$route.params.filial_id;
        this.showFilial(filial_id);
        
        this.getFilialCategories({ key: "page", value: 1, per_page:100, filial_ids: [filial_id] })
          .then(res => {
          })
          .catch(err => {
          });
      },
      watch: {
        filterForm: {
            handler: async function(newVal, oldVal) {
                await this.updatePagination({ key: "page", value: 1, per_page:10 });
                await this.updateFilter(newVal);
                this.debouncedFetchData();
            },
            deep: true,
            immediate: true
        },
      },
      computed: {
          ...mapGetters({
              filial: "filials/model",
              list: "products/filialProductsList",
              filialCategoryList: "categories/filialCategoryList",
              currencies: "currency/inventory",
              columns: "products/columns",
              pagination: "products/filialProductsPagination",
              filter: "products/filter",
              sort: "products/sort",
              mode: "MODE"
          }),
      },
      methods: {
          ...mapActions({
              getFilialCategories: "categories/getFilialCategories",
              updateList: "products/getFilialProductsByCategories",
              updatePagination: "products/updateFilialProductsPagination",
              setPagination: "products/setPagination",
              updateSort: "products/updateSort",
              updateFilter: "products/updateFilter",
              updateColumn: "products/updateColumn",
              refreshData: "products/refreshData",
              showFilial: "filials/show",
          }),
          toggleActivecategories(category_id) {
            if (this.filterForm.category_ids.includes(category_id)) {
              this.findAndRemove(this.filterForm.category_ids, category_id);
            }else{
              this.filterForm.category_ids.push(category_id);
            }
          },
          findAndRemove(array, itemToRemove) {
              const index = array.findIndex(item => item === itemToRemove);

              if (index !== -1) {
                  array.splice(index, 1);
              }
          },

          fetchData() {
              let filial_id = this.$route.params.filial_id;
              const query = {...this.filter, ...this.pagination, ...this.sort, filial_id: filial_id};
              if (!this.loadingData) {
                  this.loadingData = true;
                  this.updateList(query).then(res => {
                      this.loadingData = false
                  }).catch(err => {
                      this.loadingData = false
                  });
              }
          },
      },
     
      beforeRouteLeave (to, from, next) {
          this.$store.commit('products/EMPTY_LIST');
          next();
      },
};
</script>
  
<style scoped>

  .active-category {
    background-color: #00b2ff !important;
  }
  .mm_center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    background-color: #722a2a;
  }
  .mm_paginate {
    display: flex;
    justify-content: space-between;
  }
  .mm_border_right {
    border-right: 2px solid #d4d4d4;
    height: 100%;
  }

  .product-card {
    cursor: pointer;
  }

  .time {
    font-size: 13px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }

  .grid-text {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    background-color: #b9b9b9;
    color: white;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
  }
  
  
</style>
